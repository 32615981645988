// todas las variables que se pueden modificar => https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/dist/antd.less";

@primary-color: rgb(0, 0, 0); // primary color for all components
@link-color: #000; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 16px; // major text font size
@font-size-lg: 14px;
@font-family: 'ModerneLLWeb-Regular', serif, '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
@font-variant-base: serif;
@font-feature-settings: normal;
@font-feature-settings-base: normal;
@heading-color: rgba(0, 0, 0, 1); // heading text color
@text-color: rgba(0, 0, 0, 1); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 0px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
@select-item-selected-bg: rgb(160, 160, 160);
// Media queries breakpoints
// @screen-xs and @screen-xs-min is not used in Grid
// smallest break point is @screen-md
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1440px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);