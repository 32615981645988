.thumbImage {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
  filter: blur(4px);
  visibility: visible;
  transition: visibility .2s ease-out;
}
.thumbImageOld {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
  filter: blur(4px);
  visibility: visible;
  transition: visibility .2s ease-out;
  transform: scale(0.99);
}
.thumbImageHidden {
  visibility: hidden;
}
.image {
  grid-column: 1;
  grid-row: 1;
  height: auto;
  min-height: auto;
  width: 100%;
  vertical-align: top;
  filter: none;
  opacity: 1;
  transition: opacity .3s ease-in;
}
.imageNotLoaded {
  opacity: 0;
}
.imageNotFound {
  height: 100%;
  object-fit: scale-down;
  background: whitesmoke;
}